<template>
  <div id="Footer">
    <div class="footer_content">
      <div class="logo_box">
        <div class="logo">
          <img src="../assets/index/f_logo.png" alt="" srcset="" />
        </div>
        
      </div>
      <div class="officialStore">
        <div class="title">官方店铺</div>
        <div class="item">
          <img src="../assets/index/tianmaoT.png" alt="" srcset="" />
          <span @click="goto(6)">让茶天猫旗舰店</span>
          <span> | </span>
          <img src="../assets/index/dy2.png" alt="" srcset="" />
          <span @click="goto(2)">让茶抖音旗舰店</span>
        </div>
        <div class="item">
          <img src="../assets/index/jingdong-copy.png" alt="" srcset="" />
          <span @click="goto(3)">让茶京东自营旗舰店</span>
          <span> | </span>
          <img src="../assets/index/jingdong-copy.png" alt="" srcset="" />
          <span @click="goto(1)">让茶京东旗舰店</span>
        </div>
        
      </div>
      <div class="concatUs">
        <div class="title">联系我们</div>
        <div class="item">
          <img src="../assets/index/dianhua.png" alt="" srcset="" />
          <span>电话：400-807-0177</span>
        </div>
        <div class="item">
          <img src="../assets/index/youxiang.png" alt="" srcset="" />
          <span>邮箱： hr@rangcha.com</span>
        </div>
      </div>
      <div class="wechat">
        <div class="title">微信公众号</div>
        <div class="content">
          <div class="item">
            <img src="../assets/index/rangcha_qr.png" alt="" srcset="" />
            <div>让茶官方</div>
          </div>
          <!-- <div class="item" style="margin-left: 20px">
            <img src="../assets/index/tasantea_qr.png" alt="" srcset="" />
            <div>他山集茶官方</div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="driver"></div>
    <div class="tip" @click="toICP">让茶（北京）饮料有限公司 京ICP备2023008466号-1</div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  components: {},
  props: {},
  setup() {},
  data() {
    return {};
  },
  methods: {
    goto(index) {
      if (index == 1) {
        window.open("https://mall.jd.com/index-16153888.html?from=pc");
        /* window.location.href="https://weibo.com/u/7519768470?is_all=1" ;  */
      }
      if (index == 2) {
        window.open("https://www.iesdouyin.com/share/user/MS4wLjABAAAAauaDL6cJp_lzoJv9P9LBmUm0SZNHi11SumEM-FLCibs3_FDYR7k-gLFtF2CHEnkp?iid=MS4wLjABAAAAwbMamZ2Y04pah4uVqO1_QSwThEO1D10OvQ3wxFZc1kxX39J-S95SdjMV5ozgaQKa&life_share_ext=%7B%22life_share_id%22:%220236c224b60c42a3b1acdf35729f1f0e%22,%22enter_from%22:%22others_homepage%22,%22personal_enter_method%22:%22live_cell%22,%22personal_enter_page%22:%22live%22,%22from_user_id%22:75174695652%7D&utm_source=copy&from_ssr=1&sec_uid=MS4wLjABAAAAauaDL6cJp_lzoJv9P9LBmUm0SZNHi11SumEM-FLCibs3_FDYR7k-gLFtF2CHEnkp&tt_from=copy&utm_medium=ios&with_sec_did=1&utm_campaign=client_share&u_code=m1igdj38&from_aid=1128&did=MS4wLjABAAAA2Ppe5kC8A9kjRrrbI3wKzcyW5Ma6eIoLe0z9NrzFrPY&app=aweme&activity_info=%7B%22social_share_time%22:%221742387054%22,%22social_author_id%22:%222808593373987851%22,%22social_share_id%22:%2275174695652_1742387054%22,%22social_share_user_id%22:%2275174695652%22%7D&ecom_share_track_params=%7B%22secuid%22:%22MS4wLjABAAAAQR4Fd0uULtVu8F7Jme99XxdacSKq-jjPA8dVvl2YaSY%22,%22is_ec_shopping%22:%221%22%7D");
        // window.location.href="https://weibo.com/u/7455891248?refer_flag=1001030103_" ;
      }
      if (index == 3) {
        window.open("https://mall.jd.com/index-1000381582.html?from=pc");
        // window.location.href="https://mall.jd.com/index-10365510.html?from=pc" ;
      }
      if (index == 4) {
        window.open("https://mall.jd.com/index-10224870.html?from=pc");
        // window.location.href="https://mall.jd.com/index-10224870.html?from=pc" ;
      }
      if (index == 5) {
        window.open("https://tashanjicha.tmall.com/shop/view_shop.htm");
        // window.location.href="https://tashanjicha.tmall.com/shop/view_shop.htm" ;
      }
      if (index == 6) {
        window.open("https://rangcha.tmall.com/shop/view_shop.htm");
        // window.location.href="https://rangcha.tmall.com/shop/view_shop.htm" ;
      }
    },
    toICP(){
      window.open("https://beian.miit.gov.cn/")
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped lang="less">
#Footer {
  width: 100%;
  height: 214px;
  background: #383838;
  overflow: hidden;
  @width: 62.5%;
  @min_width: 996px;
  /* position: fixed;
  left: 0;
  bottom: 0;
  z-index: 999;
  margin-top: 214px; */
  .footer_content {
    /* width: 1200px; */
    width: @width;
    min-width: @min_width;
    height: calc(214px - 27px - 36px);
    margin: 36px auto 0 auto;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    .logo_box {
      width: 137px;
      .logo {
        width: 96px;
        height: 45px;
        margin: 0 auto;
        margin-top: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .logo_dec {
        width: 100%;
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        margin-top: 27px;
        text-align: center;
      }
    }
    .officialStore,
    .concatUs {
      width: 320px;
      .title {
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 18px;
      }
      .item {
        margin-bottom: 14px;
        img {
          width: 16px;
          height: 16px;
          padding-right: 6px;
          vertical-align: middle;
        }
        span {
          font-size: 14px;
          font-weight: 400;
          color: #c4c4c4;
          line-height: 16px;
          cursor: pointer;
        }
        & span:nth-child(3) {
          padding: 0 6px;
        }
      }
    }
    .wechat {
      width: 180px;
      .title {
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 18px;
      }
      .content {
        margin-top: 18px;
        .item {
          width: 80px;
          float: left;
          img {
            width: 65px;
            height: 65px;
            margin: 0 7.5px;
          }
          div {
            width: 100%;
            text-align: center;
            margin-top: 11px;
            font-size: 10px;
            font-weight: 400;
            color: #c4c4c4;
          }
        }
      }
    }
  }
  .driver {
    width: 100%;
    height: 1px;
    background: #ffff;
    opacity: 0.1;
  }
  .tip {
    width: 100%;
    height: 26px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 27px;
    text-align: center;
    opacity: 0.6;
    cursor: pointer;
  }
}
</style>
